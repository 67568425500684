import React, { lazy, Suspense, useEffect } from "react";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";

import { PrivateRoute } from "../../components/PrivateRoute";
import {getFeatureFlagValue} from "../../helpers";
import { isGlobalPublishAllowed, checkIsAdminRole } from "../../services/helpers";
import SsoManager from "../SsoManager";

import SessionAlertPopup from "../../components/SessionAlertPopup/SessionAlertPopup";
import PageLoader from "../../components/PageLoader";
import {get} from "../../helpers";
import componentLoader from "../../helpers/componentLoader";
import LegalAgreementModal from "../LegalAgreement/LegalAgreemenModal";
import NoPermission from "../../components/NoPermission/NoPermission";
import { getAppTitle } from "./appTitleConfig";

const config = require(`../../config/config-files/${process.env.REACT_APP_CONFIG_ENV}.json`);
const CSP_FEATURE_FLAGS = get(config, "CSP_FEATURE_FLAGS", "");

const AsyncCatalog = lazy(() => componentLoader(() => import("../ListingLayouts/CatalogLayout")));
const AsyncHomePage = lazy(() => componentLoader (() => import("../HomePage")));
const AsyncHomePageV2 = lazy(() => componentLoader (() => import("../HomepageV2")));
const AsyncManagement = lazy(() => componentLoader(() => import("../Catalog/Management")));
const AsyncManagementDraft = lazy(() => componentLoader(() => import("../Catalog/ManageDraft")));
const AsyncAdmin = lazy(() => componentLoader(() => import("../Catalog/Admin")));
const AsyncAdminTest = lazy(() => componentLoader(() => import("../Catalog/AdminTest")));
const AsyncDetailsPage = lazy(() => componentLoader(() => import("../Product/DetailsLayout")));
const AsyncDataManagement = lazy(() => componentLoader(() => import("../DataManagement")));
const AsyncUserProfile = lazy(() => componentLoader(() => import("../UserProfile/UserProfile")));
const AsyncBillings = lazy(() => componentLoader(() => import("../Billings")));
const AsyncGodView = lazy(() => componentLoader(() => import("../GodView")));
const AsyncSubscriptionDetailsView = lazy(() => componentLoader(() => import("../GodView/SubscriptionDetails")));

const AsyncProductCrud = lazy(() => componentLoader(() => import("../AddUpdateProduct/ProductCrud")));
const AsyncLegalAgreement = lazy(() => componentLoader(() => import("../LegalAgreement")));
const AsyncLegalAgreementList = lazy(() => componentLoader(() => import("../LegalAgreement/LegalAgreementList")));

const Analytics = lazy(() => componentLoader(() => import("../Analytics/Analytics")));
const UserAnalytics = lazy(() => componentLoader(() => import("../UserAnalytics/UserAnalytics")));
const ErrorPage = lazy(() => componentLoader(() => import("../ErrorPage")));
const NoAccess = lazy(() => componentLoader(() => import("../ErrorPage/noAccess")));
const Restricted = lazy(() => componentLoader(() => import("../ErrorPage/Restricted")));
const EulaManagement = lazy(() => componentLoader(() => import("../EulaManagement")));
const Logout = lazy(() => componentLoader(() => import("../Logout")));
const OrgManagement = lazy(() => componentLoader(() => import("../OrgManagement")));
const PeripheralManagement = lazy(() => componentLoader(() => import("../PeripheralManagement")));
const UserManagement = lazy(() => componentLoader(() => import("../UserManagement")));
const AsyncNetworkIPDisplay = lazy(() => componentLoader(() => import("../NetworkIPDisplay")));
const ChartDetailsPage = lazy(() => componentLoader(() => import("../Product/ChartDetailsPage/ChartDetailsPage.js")));
const AsyncLeadManagement = lazy(() => componentLoader(() => import("../LeadManagement")));
const AsyncArtifactRepository = lazy(() => componentLoader(() => import("../ArtifactRepository/index")));
const AsyncISVOnboarding = lazy(() => componentLoader(() => import("../ISVOnboarding")));
const Peri = lazy(() => componentLoader(() => import("../Peripheral")));
const MarketingAssets = lazy(() => componentLoader(() => import("../MarketingAssets")));
const SKUManagement = lazy(() => componentLoader(() => import("../SKUManagement")));
const SubscriptionManagement = lazy(() => componentLoader(() => import("../SubscriptionManagement")));
const SubscriptionsView = lazy(() => componentLoader(() => import("../SubscriptionsView")));
const ServiceCatalogs = lazy(() => componentLoader(() => import("../ServiceCatalogs")));
const DownloadList = lazy(() => componentLoader(() => import("../DownloadGrid")));

export const Routes = props => {

    const { userTokenValid, isAdmin = false, currentOrgName = "", theme, showFooter, ffClient, showNavInlineSearch } = props;
    const {
        ISV_ONBOARDING, 
        COMMERCE_FEATURES,
        SERVICE_CATALOG_CLIENT,
        SERVICE_CATALOG_CLIENT_V2,
        TRANSIENT_REPO_CLIENT, 
        HOME_PAGE_V2
    } = CSP_FEATURE_FLAGS;

    const isCommerceEnabled = getFeatureFlagValue(ffClient, COMMERCE_FEATURES);
    const isUserRole = !checkIsAdminRole();
    const history = useHistory();
    const pathname = history.location.pathname;

    const historyChangeHandler = location => {
        const pathname = get(location, "pathname", "");
        const title = getAppTitle(pathname);
        document.title = title;
    };

    useEffect(()=> {
        const unregistorHistoryListner = history.listen(historyChangeHandler);
        historyChangeHandler({pathname});
        return unregistorHistoryListner;
    });

    const renderHomePage = () => {
        const homePageV2 = getFeatureFlagValue(ffClient, HOME_PAGE_V2);
        return homePageV2 ? (<AsyncHomePageV2 {...props} 
            loggedIn={userTokenValid} 
            accessAllowed={userTokenValid} 
            showNavInlineSearch={showNavInlineSearch} 
            showFooter={showFooter}
            homePageV2={homePageV2} />) :
            (<AsyncHomePage 
                {...props} 
                loggedIn={userTokenValid} 
                accessAllowed={userTokenValid} 
                showNavInlineSearch={showNavInlineSearch} 
                howFooter={showFooter} 
            />);
    };
    return (
        <Suspense fallback={<PageLoader />}>
            { isCommerceEnabled && <LegalAgreementModal loggedIn={props.userTokenValid} currentUrl={pathname}/>}
            <Switch>
                <Route 
                    exact 
                    path="/" 
                    render={props => 
                        isAdmin ? 
                            <Redirect to={{ pathname: "/admin/services/management", state: { from: props.location } }} /> : 
                            renderHomePage()
                    } 
                />
                <Route exact path="/services" render={props => <AsyncCatalog {...props} ffClient={ffClient} accessAllowed={userTokenValid} showFooter={showFooter} />} />
                <Route path="/services/details/:id" render={routeProps => <AsyncDetailsPage loggedIn={userTokenValid} showFooter={showFooter} theme={theme} {...routeProps} ffClient={ffClient}/>} />
                <Route path="/services/solutions/:id/:appVersion/:chartVersion" render={routeProps => <ChartDetailsPage loggedIn={userTokenValid} theme={theme} {...routeProps} />} />

                <PrivateRoute exact path="/publisher-onboarding" component={AsyncISVOnboarding} accessAllowed={userTokenValid} showNotFound={!getFeatureFlagValue(ffClient, ISV_ONBOARDING)} />
                <PrivateRoute exact path="/services/management" component={AsyncManagement} accessAllowed={userTokenValid} orgName={currentOrgName} showNotFound={!isGlobalPublishAllowed()} />

                <PrivateRoute exact path="/services/management/drafts" component={AsyncManagementDraft} accessAllowed={userTokenValid && isUserRole} orgName={currentOrgName} showNotFound={!isGlobalPublishAllowed()} />

                <PrivateRoute exact path="/admin/services/management" component={AsyncAdmin} accessAllowed={userTokenValid} adminRequired={true} />
                <PrivateRoute exact path="/admin/services/test-management" component={AsyncAdminTest} accessAllowed={userTokenValid} adminRequired={true} />
                <PrivateRoute exact path="/admin/datamanagement" component={AsyncDataManagement} accessAllowed={userTokenValid} adminRequired={true} />
                <PrivateRoute exact path="/admin/usermanagement" component={UserManagement} accessAllowed={userTokenValid} adminRequired={true} />
                <PrivateRoute exact path="/admin/orgmanagement" component={OrgManagement} accessAllowed={userTokenValid} adminRequired={true} />
                <PrivateRoute exact path="/admin/peripheralmanagement" component={PeripheralManagement} accessAllowed={userTokenValid} adminRequired={true} />
                <PrivateRoute path="/admin/analytics/:view" component={Analytics} accessAllowed={userTokenValid} adminRequired={true} />
                <PrivateRoute path="/analytics/:view" component={UserAnalytics} accessAllowed={userTokenValid} />
                <PrivateRoute exact path="/admin/marketingassets" component={MarketingAssets} accessAllowed={userTokenValid} adminRequired={true}/>
                <PrivateRoute exact path="/admin/skumanagement" component={SKUManagement} accessAllowed={userTokenValid} adminRequired={true} featureFlags={[COMMERCE_FEATURES]} />
                <PrivateRoute exact path="/admin/subscriptionmanagement" component={SubscriptionManagement} accessAllowed={userTokenValid} adminRequired={true} featureFlags={[COMMERCE_FEATURES]} />
                <PrivateRoute exact path="/subscriptions/list" component={SubscriptionsView} accessAllowed={userTokenValid} featureFlags={[COMMERCE_FEATURES]} />

                <PrivateRoute path="/user/:view" component={AsyncUserProfile} accessAllowed={userTokenValid} />
                <PrivateRoute path="/product/:view" component={AsyncProductCrud} accessAllowed={userTokenValid} showNotFound={!isGlobalPublishAllowed()} showFooter={showFooter} ffClient={ffClient}/>
                <PrivateRoute exact path="/subscriptions/details/:key" component={AsyncSubscriptionDetailsView} accessAllowed={userTokenValid} />
                <PrivateRoute exact path={getFeatureFlagValue(ffClient, COMMERCE_FEATURES) ? "/deployment/:key" : "/subscription/:key"} component={AsyncGodView} ffClient={ffClient} accessAllowed={userTokenValid} />
                <PrivateRoute path="/billing" component={AsyncBillings} accessAllowed={userTokenValid} />

                <PrivateRoute exact path="/eulamanagement" component={EulaManagement} accessAllowed={userTokenValid} />
                <PrivateRoute exact path="/egress-info" component={AsyncNetworkIPDisplay} accessAllowed={userTokenValid} adminRequired={false} />
                <PrivateRoute exact path="/leads" component={AsyncLeadManagement} accessAllowed={userTokenValid} adminRequired={false} />

                <Route exact path="/legal-agreement" render={props => <AsyncLegalAgreement {...props} ffClient={ffClient} accessAllowed={userTokenValid} />}  />
                <PrivateRoute exact path="/distribution-registry" component = {AsyncArtifactRepository} accessAllowed={userTokenValid} adminRequired={false} featureFlags={[
                    TRANSIENT_REPO_CLIENT,
                    SERVICE_CATALOG_CLIENT
                ]}/>
                <PrivateRoute exact path="/admin/legal-agreement" component={AsyncLegalAgreementList} accessAllowed={userTokenValid} adminRequired={true} />
                <PrivateRoute 
                    path="/servicecatalog" 
                    component={ServiceCatalogs}  
                    accessAllowed={userTokenValid} 
                    featureFlags={[SERVICE_CATALOG_CLIENT_V2]}
                />
                <PrivateRoute exact path="/downloads/list" component={DownloadList} accessAllowed={userTokenValid} />
                
                {/* No Auth is required for below routes */}
                <Route path="/logout" component={Logout} />
                <Route exact path="/sso/finish" component={SsoManager} />
                <Route exact path="/no-access" component={NoAccess} />
                <Route exact path="/no-permission" render={ props => <NoPermission 
                    title={props.location.state && props.location.state.title}
                    buttonText={props.location.state && props.location.state.buttonText}
                    reRoutePath={props.location.state && props.location.state.reRoutePath}
                    {...props}
                /> 
                }/>
                <Route exact path="/restricted" component={Restricted} />
                <Route path="/vmware-validated-peripherals" component={Peri} />
                {/* VSX Redirect */}
                <Redirect from="/vsx/content/vmware-validated-peripherals" to="/vmware-validated-peripherals" />
                <Redirect from="/vsx/solutions/:param" to="/services/details/:param/?slug=true" />
                <Redirect from="/vsx" to="/" />
                <Route path="/publisher"><Redirect to="/?redirect-from-vsx=true" /></Route>
                <Route
                    path="*"
                    render={props => <ErrorPage
                        {...props}
                        code={props.location.state && props.location.state.code}
                        title={props.location.state && props.location.state.title} />}
                />


            </Switch>
            <SessionAlertPopup />
        </Suspense>
    );
};
